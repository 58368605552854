var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.domain.freessldomain.process !== "Processing"
    ? _c(
        "v-menu",
        {
          attrs: { transition: "slide-x-transition" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-icon",
                      _vm._g(
                        {
                          staticStyle: { position: "absolute", right: "5px" },
                          attrs: { small: "" }
                        },
                        on
                      ),
                      [_vm._v("keyboard_arrow_down")]
                    )
                  ]
                }
              }
            ],
            null,
            false,
            1539514929
          )
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.currentMenus, function(item, i) {
              return _c(
                "v-list-tile",
                { key: i, on: { click: item.handler } },
                [_c("v-list-tile-title", [_vm._v(_vm._s(item.text))])],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }