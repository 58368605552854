<template>
    <v-container class="panel-wrapper domain-wrapper panel-database-detail pa-0" grid-list-lg fluid>
        <v-layout column>
            <v-flex>
                <TabCard title="Site & SSL">
                    <v-layout wrap style="margin-top: -24px">
                        <v-flex xs12 md10>
                            <p style="color: #666;margin: 0;" class="fs-3 font-weight-regular pt-4">
                                This is where you manage your websites, DNS, SSL, Domain, and URL redirection.
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="primary">report</v-icon>
                                    </template>
                                    <span>Notes:</span>
                                    <ul>
                                        <li>Please update DNS A record to point your domain to {{appInfo.ip[0]}}</li>
                                        <li>Please click “Enable DNS” and update name server to ns1,ns2.cloudclusters.net if you
                                            want to use our name servers
                                        </li>
                                        <li>You can enable free SSL after your domain has been resolved to {{appInfo.ip[0]}}</li>
                                        <li>You can manage URL redirection for SEO</li>
                                    </ul>
                                </v-tooltip>
                            </p>
                            <p class="mb-0 mt-1" v-if="appInfo.product_name === 'WordPress'" style="color:#039BE5;">You are
                                currently using <span :style="{color: $vuetify.theme.error}">{{list?list.length:0}} </span> out of
                                {{appInfo.max_site_number}} {{appInfo.product_name}} sites</p>
                        </v-flex>
                        <template v-if="appInfo.product_name === 'WordPress'">
                            <v-flex xs12 md2 style="display: flex;justify-content: flex-end">
                                <v-btn :disabled="!list || list.length >= appInfo.max_site_number" class="mt-3 mx-0 text-none"
                                       color="primary" @click="$refs.addSite.open()">
                                    <v-icon dark left>add</v-icon>
                                    Add New Site
                                </v-btn>
                            </v-flex>
                        </template>
                    </v-layout>
                    <template v-if="list">
                        <Site v-for="(site,idx) in list" :key="site.id" :idx="idx" :site-detail="site" class="mt-4" @delete-site="deleteConfirm"
                              @refresh-site="updateSite"></Site>
                    </template>
                    <template v-if="!list">
                        <div style="height: 400px;">
                            <Spin></Spin>
                        </div>
                    </template>
                    <template v-if="list && list.length===0">
                        <div style="height: 400px;">
                            <Empty></Empty>
                        </div>
                    </template>
                    <AddSite ref="addSite" @confirm="addSiteHandler"></AddSite>
                    <Confirm ref="deleteSite" @confirm="deleteSiteHandler">
                        <span slot="title">Delete Site</span>
                        <template v-slot:content="{options}">
                            <span v-if="options.item && options.item.nickname"> Are you sure you want to delete  <b> {{options.item.nickname}}</b>?</span>
                        </template>
                    </Confirm>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

    import Site from './_source/Site'
    import AddSite from './_source/AddSite'
    import Spin from '@/components/Spin.vue'
    import Empty from "@/components/Empty.vue";
    import Confirm from '@/components/Confirm'
    import TabCard from '@/components/TabCard.vue'
    import {mapState} from "vuex";

    export default {
        name: "DomainSSL",
        components: {
            Site, AddSite, Confirm, Spin, Empty, TabCard
        },
        data() {
            return {
                triggerGuidance : false,
                list: null,
            }
        },
        computed: {
            ...mapState('application', ['appInfo']),
        },
        methods: {
            deleteConfirm(site) {
                this.$refs.deleteSite.open({item: site})
            },
            deleteSiteHandler({item}) {
                return this.$http.delete(`/app_sites/${item.id}`).then(() => {
                    this.getAllSites()
                    // this.$message.success('The system is removing the site from your application. ')
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            getAllSites() {
                return this.$http.get(`/app_sites/`, {
                    params: {
                        package_uuid: this.appId
                    }
                }).then(({results}) => {
                    this.list = results
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            addSiteHandler(option) {
                return this.$http.post(`/app_sites/`, {
                    stack: this.appInfo.stacks[0].uuid,
                    nickname: option.nick_name
                }).then(() => {
                    // this.$message.success('Create Site Successfully')
                    this.getAllSites()
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            updateSite(item, status) {
                let ids = this.list.map(o => o.id)
                let idx = ids.indexOf(item.id)
                if (status && status === 'DeleteSuccess') {
                    this.list.splice(idx, 1)
                } else {
                    this.$set(this.list, idx, item)
                }
            },
        },
        created() {
            this.appId = this.$route.params.appId
            this.getAllSites()
            this.$SDK.track({pageName:'Site & SSL',productName:this.appInfo.product_name})
        }
    }
</script>

<style lang="scss">
    .domain-wrapper {
        .span-font {
            font-size: small;
        }

        .elliptic-box {
            background-color: #10C610;
            border-radius: 10px;
            display: table-cell;
            vertical-align: middle;
            color: #ffffff;
        }

        .text-box {
            display: flex;
            align-items: center;
            width: 140px;
            border: 1px solid #3F51B5;
            position: relative;
            height: 30px;
            border-radius: 5px;
        }
    }
</style>
