<template>
    <v-dialog v-model="dialog" max-width="600" persistent no-click-animation>
        <v-form ref="form" v-insert="{tagName:'input',attrs:{style:'display:none',type:'text'}}">
            <v-card>
                <v-card-title>
                    <div class="title">Create New Domain</div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout column>
                        <v-flex>
                            <v-text-field v-model="formData.domain_name"
                                          :rules="[rules.required , rules.domain,rules.notAddWWW]"
                                          clearable>
                                <template v-slot:label>
                                    <RequiredDot>Domain Name</RequiredDot>
                                </template>
                            </v-text-field>
                        </v-flex>
                        <v-flex class="mt-2">
                            <p class="mb-0">Would you like to use our <a href="https://en.wikipedia.org/wiki/Name_server">name servers</a>, {{server_name1}}&{{server_name2}}, for this domain?</p>
                        </v-flex>
                        <v-flex>
                            <v-radio-group v-model="formData.is_our_nameserver" mandatory>
                                <v-radio
                                        :value="false"
                                >
                                    <template v-slot:label>
                                        <div>No, I will use my own name servers(and point my domain to IP address <b v-if="deploymentInfo&&deploymentInfo.ips">{{deploymentInfo.ips[0]}}</b>.)</div>
                                    </template>
                                </v-radio>
                                <v-radio
                                        label="Yes, I want to use your name servers(www and blank DNS A records will be created by default.)"
                                        :value="true"
                                ></v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex class="mt-3" style="display: inline-block;text-align: right;">
                            <v-btn class="text-none" color="blue darken-1" flat @click="close()">Cancel</v-btn>
                            <v-btn class="text-none" color="primary" @click="ok()" :loading="loading">Create</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import RequiredDot from '@/components/RequiredDot.vue'
    import rules from '@/module/rules/index.js'
    import {server_name1, server_name2} from "@/module/constant.js"
    import {mapState} from 'vuex'


    export default {
        name: "AddDomain",
        components: {
            RequiredDot
        },
        mixins: [loadingMixin],
        data() {
            return {
                server_name1: server_name1,
                server_name2: server_name2,
                rules: {
                    notAddWWW:(value) => {
                        const pattern = /^[Ww][Ww][Ww]\./
                        return !value||!pattern.test(value)||'No need to include www in your domain name'
                    },
                    ...rules
                },
                formData: {
                    domain_name: '',
                    is_our_nameserver: false,
                }
            }
        },
        computed: {
            ...mapState('application', ['deploymentInfo']),
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.formData.domain_name = ''
                    this.formData.is_our_nameserver = false
                    this.$refs.form.resetValidation()
                }
            },
        },
        methods: {
            $validator() {
                return this.$refs.form.validate()
            }
        }
    }
</script>

<style lang="scss">

</style>
