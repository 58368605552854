var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        {
          directives: [
            {
              name: "insert",
              rawName: "v-insert",
              value: {
                tagName: "input",
                attrs: { style: "display:none", type: "text" }
              },
              expression:
                "{tagName:'input',attrs:{style:'display:none',type:'text'}}"
            }
          ],
          ref: "form"
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Create New Domain")
                ])
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [
                                _vm.rules.required,
                                _vm.rules.domain,
                                _vm.rules.notAddWWW
                              ],
                              clearable: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c("RequiredDot", [_vm._v("Domain Name")])
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formData.domain_name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "domain_name", $$v)
                              },
                              expression: "formData.domain_name"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-flex", { staticClass: "mt-2" }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("Would you like to use our "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://en.wikipedia.org/wiki/Name_server"
                              }
                            },
                            [_vm._v("name servers")]
                          ),
                          _vm._v(
                            ", " +
                              _vm._s(_vm.server_name1) +
                              "&" +
                              _vm._s(_vm.server_name2) +
                              ", for this domain?"
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { mandatory: "" },
                              model: {
                                value: _vm.formData.is_our_nameserver,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "is_our_nameserver",
                                    $$v
                                  )
                                },
                                expression: "formData.is_our_nameserver"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: false },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            "No, I will use my own name servers(and point my domain to IP address "
                                          ),
                                          _vm.deploymentInfo &&
                                          _vm.deploymentInfo.ips
                                            ? _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.deploymentInfo.ips[0]
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(".)")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label:
                                    "Yes, I want to use your name servers(www and blank DNS A records will be created by default.)",
                                  value: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "mt-3",
                          staticStyle: {
                            display: "inline-block",
                            "text-align": "right"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "blue darken-1", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "primary", loading: _vm.loading },
                              on: {
                                click: function($event) {
                                  return _vm.ok()
                                }
                              }
                            },
                            [_vm._v("Create")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }