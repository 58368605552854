var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("div", {
                  staticClass: "title",
                  domProps: {
                    textContent: _vm._s(
                      _vm.recordId ? "Edit DNS Record" : "Add DNS Record"
                    )
                  }
                })
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.typeList,
                              rules: [_vm.rules.required]
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c("RequiredDot", [_vm._v("Record Type")])
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formData.dns_type,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "dns_type", $$v)
                              },
                              expression: "formData.dns_type"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [
                                _vm.formData.dns_type === "TXT"
                                  ? function(value) {
                                      return !value || value.length <= 255
                                    }
                                  : _vm.rules.notSpecialSymbol()
                              ],
                              label: "Record Name"
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.formData.dns_type === "A"
                        ? _c(
                            "v-flex",
                            [
                              _vm.formData.dns_type === "A"
                                ? _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.required, _vm.rules.ip]
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c("RequiredDot", [_vm._v("IP")])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2877308323
                                    ),
                                    model: {
                                      value: _vm.formData.ip,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "ip", $$v)
                                      },
                                      expression: "formData.ip"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      ["MX", "CNAME", "NS", "TXT"].indexOf(
                        _vm.formData.dns_type
                      ) !== -1
                        ? _c(
                            "v-flex",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    _vm.rules.required,
                                    _vm.formData.dns_type === "TXT"
                                      ? function(value) {
                                          return (
                                            !value ||
                                            value.length <= 255 ||
                                            "Max 255 characters"
                                          )
                                        }
                                      : _vm.rules.domain
                                  ]
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c("RequiredDot", [
                                            _vm._v("Record Data")
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3210536871
                                ),
                                model: {
                                  value: _vm.formData.ip,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "ip", $$v)
                                  },
                                  expression: "formData.ip"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.dns_type === "MX"
                        ? _c(
                            "v-flex",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    function(value) {
                                      return (
                                        !!value || value === 0 || "required"
                                      )
                                    },
                                    _vm.rules.number0
                                  ]
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c("RequiredDot", [
                                            _vm._v("MX Priority")
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  288609405
                                ),
                                model: {
                                  value: _vm.formData.priority,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "priority", $$v)
                                  },
                                  expression: "formData.priority"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        {
                          staticStyle: {
                            display: "inline-block",
                            "text-align": "right"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "blue darken-1", flat: "" },
                              on: { click: _vm.close }
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "primary", loading: _vm.loading },
                              on: { click: _vm.ok }
                            },
                            [_vm._v("Submit")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }