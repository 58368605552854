var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        {
          directives: [
            {
              name: "insert",
              rawName: "v-insert",
              value: {
                tagName: "input",
                attrs: { style: "display:none", type: "text" }
              },
              expression:
                "{tagName:'input',attrs:{style:'display:none',type:'text'}}"
            }
          ],
          ref: "form"
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("div", { staticClass: "title" }, [_vm._v("Add New Site")])
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    {
                      directives: [
                        { name: "single-input", rawName: "v-single-input" }
                      ],
                      attrs: { column: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [
                                _vm.rules.required,
                                _vm.rules.counter32,
                                _vm.rules.n_or_c,
                                _vm.rules.noNumOnly
                              ],
                              hint:
                                "Maximum 32 characters, and contain only numbers and lowercase letters.",
                              "persistent-hint": "",
                              clearable: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c("RequiredDot", [_vm._v("Site Nickname")])
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formData.nick_name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "nick_name", $$v)
                              },
                              expression: "formData.nick_name"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-flex", { staticClass: "mt-2" }, [
                        _c("ul", { staticClass: "site-ul" }, [
                          _c("li", [
                            _vm._v(
                              "This nickname will be used to uniquely identify your site in Control Panel."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Visitors  to your site will never see the nickname"
                            )
                          ]),
                          _c("li", [_vm._v("It can not be changed")])
                        ])
                      ]),
                      _c(
                        "v-flex",
                        {
                          staticClass: "mt-3",
                          staticStyle: {
                            display: "inline-block",
                            "text-align": "right"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "blue darken-1", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "primary", loading: _vm.loading },
                              on: {
                                click: function($event) {
                                  return _vm.ok()
                                }
                              }
                            },
                            [_vm._v("Create")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }