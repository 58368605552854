<template>
    <v-progress-circular class="ml-1" size="25" indeterminate color="success"></v-progress-circular>
</template>

<script>
    let interver = 5000
    export default {
        name: "FreeSSLProcess",
        props: {
            id: Number
        },
        data() {
          return {
              timer: null,
              isSuccess: false
          }
        },
        methods: {
            getFreeSSLStatus() {
                return this.$http.get(`/freessls/${this.id}/?is_polling=true`)
            },
            tick(){
                if (this.isSuccess) {
                    return
                }
                this.getFreeSSLStatus().then(res => {
                    if(res.process === 'Processing'){
                        this.timer =  setTimeout(() => {
                            this.tick()
                        },interver)
                    }else {
                        if (res.process === 'Failed') {
                            this.$message.error({
                              content: res.msg,
                              duration: 0,
                              closable: true
                            })
                        } else {
                            this.$message.success('The free SSL was installed successfully.')
                        }
                        this.isSuccess = true
                        this.$emit('success')
                        clearTimeout(this.timer)
                    }
                }).catch(() => {
                    this.timer =  setTimeout(() => {
                        this.tick()
                    },interver)
                })
            }
        },
        created(){
            this.tick()
        },
        beforeDestroy(){
            this.isSuccess = true
            clearTimeout(this.timer)
        }
    }
</script>

<style lang="scss">

</style>
