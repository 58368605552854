var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "span",
                  { staticClass: "title", staticStyle: { display: "block" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.appInfo.product_name.toLowerCase() === "odoo"
                          ? "Reset Master Password"
                          : "Reset Password"
                      )
                    )
                  ]
                ),
                _c("br")
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "pa-0": "", "grid-list-lg": "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.userList,
                          rules: [_vm.rules.required],
                          required: "",
                          clearable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [_c("RequiredDot", [_vm._v("Admin User")])]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          type: "password",
                          rules: [
                            _vm.rules.required,
                            _vm.rules.moreCounter,
                            _vm.rules.n_and_c
                          ],
                          required: "",
                          clearable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("RequiredDot", [_vm._v("New password")])
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.formData.password,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "password", $$v)
                          },
                          expression: "formData.password"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          type: "password",
                          rules: [
                            function(value) {
                              return (
                                !value ||
                                value === _vm.formData.password ||
                                "Passwords do not match."
                              )
                            },
                            _vm.rules.required,
                            _vm.rules.moreCounter
                          ],
                          clearable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("RequiredDot", [
                                  _vm._v("New Password Confirmation")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.close }
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "primary", loading: _vm.loading },
                      on: { click: _vm.ok }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }