<template>
    <v-dialog v-model="dialog" max-width="500">
        <v-form ref="form">
            <v-card>
                <v-card-title>
                    <div class="title" v-text="recordId?'Edit DNS Record': 'Add DNS Record'"></div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout column>
                        <v-flex>
                            <v-select v-model="formData.dns_type" :items="typeList" :rules="[rules.required]">
                                <template v-slot:label>
                                    <RequiredDot>Record Type</RequiredDot>
                                </template>
                            </v-select>
                        </v-flex>
                        <v-flex>
                            <v-text-field v-model="formData.name" :rules="[formData.dns_type==='TXT'?value => !value || value.length <= 255:rules.notSpecialSymbol()]" label="Record Name" >
                            </v-text-field>
                        </v-flex>
                        <v-flex v-if="formData.dns_type==='A'">
                            <v-text-field v-model="formData.ip" v-if="formData.dns_type==='A'" :rules="[rules.required,rules.ip]">
                                <template v-slot:label>
                                    <RequiredDot >IP</RequiredDot>
                                </template>
                            </v-text-field>
                        </v-flex>
                        <v-flex v-if="['MX','CNAME','NS','TXT'].indexOf(formData.dns_type)!== -1">
                            <v-text-field v-model="formData.ip" :rules="[rules.required,formData.dns_type==='TXT'?value => !value || value.length <= 255 || 'Max 255 characters':rules.domain]">
                                <template v-slot:label>
                                    <RequiredDot>Record Data</RequiredDot>
                                </template>
                            </v-text-field>
                        </v-flex>
                        <v-flex v-if="formData.dns_type==='MX'">
                            <v-text-field v-model="formData.priority" :rules="[value => {return !!value||value === 0||'required'},rules.number0]">
                                <template v-slot:label>
                                    <RequiredDot>MX Priority</RequiredDot>
                                </template>
                            </v-text-field>
                        </v-flex>
                        <v-flex style="display: inline-block;text-align: right;">
                            <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                            <v-btn class="text-none" color="primary" @click="ok" :loading="loading">Submit</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import RequiredDot from '@/components/RequiredDot.vue'
    import rules from '@/module/rules/index.js'

    export default {
        name: "CreateRecord",
        components: {RequiredDot},
        mixins: [loadingMixin],
        data() {
            return {
                recordId:'',
                typeList: ['A','MX','NS','TXT','CNAME'],
                formData: {
                    name: "",
                    ip: "",
                    priority: 0,
                    dns_type: "A"
                },
                rules: {
                    ...rules
                }
            }
        },
        watch: {
            dialog() {
                if (!this.dialog) {
                    this.formData = {
                        name: "",
                        ip: "",
                        priority: 1,
                        dns_type: ""
                    }
                    this.recordId = ''
                    this.$refs.form.resetValidation()
                }
            },
            'formData.dns_type': {
                handler() {
                    this.$nextTick(()=>{
                        this.$validator()
                    })
                }
            }
        },
        methods: {
            open(record) {
                this.dialog = true
                if (record) {
                    this.formData = Object.assign(this.formData,record)
                    this.recordId = record.id
                }
            },
            getEditStatus() {
                return this.recordId
            },
            $validator() {
                return this.$refs.form.validate()
            }
        }
    }
</script>

<style lang="scss">

</style>