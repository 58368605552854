var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("div", { staticClass: "title" }, [
                  _vm._v("URL Redirection Management")
                ])
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-subheader",
                            {
                              staticClass: "pa-0 subheading",
                              staticStyle: { height: "24px", color: "#444" }
                            },
                            [_vm._v("Http Redirection Option")]
                          ),
                          _c("v-switch", {
                            staticClass: "mt-2 pr-2",
                            attrs: {
                              disabled: !_vm.hasSSL,
                              color: "primary",
                              dense: "",
                              "hide-details": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "14px" } },
                                      [_vm._v("Redirect HTTP to HTTPs")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formData.to_https,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "to_https", $$v)
                              },
                              expression: "formData.to_https"
                            }
                          }),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.hasSSL,
                                  expression: "!hasSSL"
                                }
                              ],
                              staticStyle: {
                                color: "red",
                                "padding-left": "44px",
                                "font-size": "13px"
                              }
                            },
                            [
                              _vm._v(
                                "Unavailable as you have not enabled SSL for the domain."
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.can_redirect
                        ? _c(
                            "v-flex",
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0 subheading",
                                  staticStyle: { height: "24px", color: "#444" }
                                },
                                [_vm._v("URL Redirection Options")]
                              ),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-1",
                                  model: {
                                    value: _vm.formData.redirect,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "redirect", $$v)
                                    },
                                    expression: "formData.redirect"
                                  }
                                },
                                _vm._l(_vm.selectItems, function(o, k) {
                                  return _c("v-radio", {
                                    key: k,
                                    attrs: {
                                      color: "primary",
                                      label: o.text,
                                      value: o.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        {
                          staticClass: "mt-3",
                          staticStyle: {
                            display: "inline-block",
                            "text-align": "right"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "blue darken-1", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "primary", loading: _vm.loading },
                              on: {
                                click: function($event) {
                                  return _vm.ok()
                                }
                              }
                            },
                            [_vm._v("Submit")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }