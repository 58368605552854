var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "site-panel" },
    [
      _c(
        "v-card",
        {
          staticStyle: { cursor: "default" },
          attrs: { hover: _vm.siteDetail.status === "Success" }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "py-0 pr-0 title", staticStyle: { height: "40px" } },
            [
              _c("span", { style: { color: _vm.$vuetify.theme.primary } }, [
                _vm._v(_vm._s(_vm.siteDetail.nickname))
              ]),
              _c("v-spacer"),
              !_vm.siteDetail.is_default
                ? [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          attrs: {
                            disabled:
                              ["Installing", "Deleting"].indexOf(
                                _vm.siteDetail.status
                              ) !== -1,
                            icon: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("delete-site", _vm.siteDetail)
                            }
                          }
                        },
                        _vm.on
                      ),
                      [
                        _c("i", {
                          staticClass: "fa fa-trash fa-lg",
                          staticStyle: { color: "#999" },
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              [
                _vm.information.length
                  ? _c(
                      "v-layout",
                      {
                        staticClass: "px-3 py-2 mt-2 body-1",
                        staticStyle: { background: "#eaebec" },
                        attrs: { wrap: "" }
                      },
                      _vm._l(_vm.information, function(item, idx) {
                        return _c(
                          "v-flex",
                          {
                            key: idx,
                            attrs: {
                              sm12: "",
                              xs12: "",
                              md12: "",
                              "d-flex": ""
                            }
                          },
                          [
                            _c(
                              "v-layout",
                              [
                                _c(
                                  "v-flex",
                                  { staticClass: "pa-1", attrs: { xs3: "" } },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "v-flex",
                                  { staticClass: "pa-1", attrs: { xs9: "" } },
                                  [
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "http",
                                          rawName: "v-http",
                                          value: item.value,
                                          expression: "item.value"
                                        }
                                      ],
                                      staticClass: "text-truncate",
                                      staticStyle: {
                                        display: "block",
                                        "line-height": "1.5em !important"
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-layout",
                  [
                    _c("v-data-table", {
                      staticClass: "domain-table elevation-0",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.domainList,
                        "hide-actions": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "items",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                { staticClass: "pl-2" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "no-wrap": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { grow: "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("language")]
                                          ),
                                          _vm._v(
                                            " \n                                            "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "span-font text-truncate",
                                              attrs: {
                                                href: item.domain_url,
                                                target: "_blank"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.domain_name))]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-flex", [
                                        item.is_primary && _vm.showPrimary
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "elliptic-box span-font px-2"
                                              },
                                              [_vm._v("Primary")]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.deploymentInfo && _vm.deploymentInfo.ips
                                      ? _vm.deploymentInfo.ips[0]
                                      : ""
                                  )
                                )
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticStyle: { flex: "0 1 140px" },
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-box" },
                                            [
                                              item.is_platform_default
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "span-font mx-2",
                                                        attrs: {
                                                          title:
                                                            "Install SSL certificate for your site."
                                                        }
                                                      },
                                                      [_vm._v("None")]
                                                    )
                                                  ]
                                                : [
                                                    item.freessldomain.id &&
                                                    item.freessldomain
                                                      .process !== "Failed"
                                                      ? [
                                                          item.freessldomain
                                                            .process ===
                                                          "Processing"
                                                            ? _c(
                                                                "FreeSSLProcess",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      item
                                                                        .freessldomain
                                                                        .id
                                                                  },
                                                                  on: {
                                                                    success:
                                                                      _vm.refreshSite
                                                                  }
                                                                }
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "span-font elliptic-box px-2 mx-1"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Let's Encrypt"
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      : item.certificate
                                                          .certificate
                                                      ? [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "span-font elliptic-box px-2 mx-1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Custom Cert"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "span-font mx-2"
                                                            },
                                                            [_vm._v("None")]
                                                          )
                                                        ],
                                                    !item.is_platform_default
                                                      ? _c("CertMenus", {
                                                          attrs: {
                                                            domain: item
                                                          },
                                                          on: {
                                                            operator:
                                                              _vm.operatorHandler,
                                                            installCert: function(
                                                              $event
                                                            ) {
                                                              return _vm.$refs.installcert.open(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ]
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("td", { staticClass: "pr-0" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "44px"
                                    }
                                  },
                                  [
                                    item.freessldomain.process !== "Processing"
                                      ? _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              disabled: _vm.showMenus(item),
                                              transition: "slide-x-transition"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              disabled: _vm.showMenus(
                                                                item
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-cog fa-lg",
                                                            style: {
                                                              color: _vm.showMenus(
                                                                item
                                                              )
                                                                ? "#ccc"
                                                                : _vm.$vuetify
                                                                    .theme
                                                                    .primary
                                                            },
                                                            attrs: {
                                                              title:
                                                                "Manage DNS, URL redirection, etc.",
                                                              "aria-hidden":
                                                                "true"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _vm.showPrimary
                                                  ? _c(
                                                      "v-list-tile",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            item.is_primary
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            !item.is_primary &&
                                                              _vm.$refs.makePrimary.open(
                                                                { domain: item }
                                                              )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          [
                                                            _vm._v(
                                                              "Make primary"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                (!item.is_platform_default &&
                                                  item.is_our_nameserver) ||
                                                item.is_enabled
                                                  ? _c(
                                                      "v-list-tile",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.dns.open(
                                                              { item: item }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          [_vm._v("Manage DNS")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !item.is_our_nameserver &&
                                                !item.is_platform_default &&
                                                !item.is_enabled
                                                  ? _c(
                                                      "v-list-tile",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.enabledns.open(
                                                              {
                                                                domain_uuid:
                                                                  item.uuid
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          [_vm._v("Enable DNS")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !item.is_platform_default
                                                  ? _c(
                                                      "v-list-tile",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.redirect.open(
                                                              { item: item }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          [
                                                            _vm._v(
                                                              "URL Redirect"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !item.is_platform_default
                                                  ? _c(
                                                      "v-list-tile",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.deleteDomain.open(
                                                              { domain: item }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          [_vm._v("Delete")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              _vm.siteDetail.status !== "Success"
                ? _c("SiteProcess", {
                    attrs: { "site-detail": _vm.siteDetail },
                    on: { "done-refresh": _vm.refreshSite }
                  })
                : _vm._e()
            ],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: " mx-0 text-none",
                  attrs: {
                    title: "Add your own domain.",
                    id: "site_idx_" + _vm.idx,
                    disabled: _vm.siteDetail.status !== "Success",
                    small: "",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$refs.adddomain.open()
                    }
                  }
                },
                [_vm._v(" Create Domain")]
              ),
              [
                "phpclusters",
                "javaclusters",
                "pythonclusters",
                "aspnetclusters",
                "nodejsclusters"
              ].indexOf(_vm.productName) == "-1"
                ? _c(
                    "v-btn",
                    {
                      staticClass: " mx-0 text-none ml-3",
                      attrs: {
                        title: "Reset the password of your admin user.",
                        disabled: _vm.siteDetail.status !== "Success",
                        outline: "",
                        small: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.password.open()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.passwordText))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("Redirect", { ref: "redirect", on: { confirm: _vm.updateRedirect } }),
      _c("InstallCert", {
        ref: "installcert",
        on: { confirm: _vm.installCertificate }
      }),
      _c("AddDomain", { ref: "adddomain", on: { confirm: _vm.addDomain } }),
      _c("DNS", { ref: "dns" }),
      _vm.siteDetail.status === "Success" &&
      [
        "phpclusters",
        "javaclusters",
        "pythonclusters",
        "aspnetclusters",
        "nodejsclusters"
      ].indexOf(_vm.productName) == "-1"
        ? _c("UserPassword", {
            ref: "password",
            attrs: { "site-detail": _vm.siteDetail },
            on: { confirm: _vm.updatePassword }
          })
        : _vm._e(),
      _c(
        "Confirm",
        {
          ref: "deleteDomain",
          on: { confirm: _vm.deleteDomain },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function(ref) {
                var options = ref.options
                return [
                  options.domain && options.domain.domain_name
                    ? _c("span", [
                        _vm._v(" Are you sure you want to delete  "),
                        _c("b", [
                          _vm._v(" " + _vm._s(options.domain.domain_name))
                        ]),
                        _vm._v(
                          "?\n                        Without it, http(s)://" +
                            _vm._s(options.domain.domain_name) +
                            ".com will not be accessible.\n                "
                        )
                      ])
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Delete domain")
          ])
        ]
      ),
      _c(
        "Confirm",
        {
          ref: "makePrimary",
          on: { confirm: _vm.makePrimary },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function(ref) {
                var options = ref.options
                return [
                  options.domain && options.domain.domain_name
                    ? _c("span", [
                        _vm._v("Are you sure you want to make "),
                        _c("b", [
                          _vm._v(_vm._s(options.domain.domain_name) + " ")
                        ]),
                        _vm._v(" primary?")
                      ])
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Make primary")
          ])
        ]
      ),
      _c(
        "Confirm",
        {
          ref: "enabledns",
          on: { confirm: _vm.enableDNS },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function() {
                return [
                  _c("span", [
                    _vm._v(
                      "You’ll need to update the name servers at your current domain registrar as below:"
                    ),
                    _c("br"),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.server_name1) +
                          " & " +
                          _vm._s(_vm.server_name2)
                      )
                    ]),
                    _c("br"),
                    _vm._v(
                      "\n                    Please be aware that changes may take up to 24 hours to propagate.\n                "
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Enable DNS")
          ])
        ]
      ),
      _c("Confirm", {
        ref: "cert",
        on: { confirm: _vm.certCallback },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var options = ref.options
              return [_c("span", [_vm._v(" " + _vm._s(options.content))])]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }