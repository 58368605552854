<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation>
            <v-card>
                <v-card-title>
                    <span class="title" style="display: block;">{{appInfo.product_name.toLowerCase()==='odoo'?'Reset Master Password':'Reset Password'}}</span><br/>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container fluid pa-0 grid-list-lg>
                        <v-select :items="userList"
                                  v-model="formData.name"
                                  :rules="[rules.required]"
                                   required clearable>
                            <template v-slot:label>
                                <RequiredDot>Admin User</RequiredDot>
                            </template>
                        </v-select>
                        <v-text-field
                                v-model="formData.password"
                                type="password"
                                :rules="[rules.required,rules.moreCounter,rules.n_and_c]"
                                required
                                clearable>
                            <template v-slot:label>
                                <RequiredDot>New password</RequiredDot>
                            </template>
                        </v-text-field>
                        <v-text-field
                                type="password"
                                :rules="[value => !value||value===formData.password||'Passwords do not match.',rules.required,rules.moreCounter]"
                                clearable>
                            <template v-slot:label>
                                <RequiredDot>New Password Confirmation</RequiredDot>
                            </template>
                        </v-text-field>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" @click="ok" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import RequiredDot from '@/components/RequiredDot.vue'
    import rules from '@/module/rules/index.js'
    import {mapGetters, mapState} from "vuex";
    export default {
        name: "UserPassword",
        mixins: [loadingMixin],
        components: {RequiredDot},
        data() {
            return {
                rules,
                formData: {
                    name: '',
                    password: '',
                },
                userList:[]
            }
        },
        props : ['siteDetail'],
        computed: {
            ...mapState('application', ['appInfo']),
            ...mapGetters('application', ['getServiceId']),
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.reset()
                    this.formData =  {
                        name: '',
                        password: '',
                    }
                }
            }
        },
        methods: {
            getUserList() {
                this.$http.get(`/admins/`, {
                    params: {
                        service_uuid: this.getServiceId(this.appInfo),
                        app_site_id: this.siteDetail.id
                    }
                }).then((res) => {
                    this.userList = res
                    this.formData.name = res[0]||''
                }).catch(() => {
                    // this.$message.error('It failed to list admin users. You can refresh this page later or reach us at support@cloudclusters.io')
                })
            },
            open(options){
                this.formData = Object.assign(this.formData,options);
                this.dialog = true
            },
            $validator(){
                return this.$refs.form.validate()
            }
        },
        created() {
            this.getUserList()
        }
    }
</script>

<style lang="scss">

</style>
