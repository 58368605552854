var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "sticky",
                top: "0",
                background: "#fff",
                width: "100%",
                "z-index": "200"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  staticStyle: { padding: "20px", display: "inline-block" }
                },
                [_vm._v("\n                DNS Settings\n            ")]
              ),
              _c(
                "v-btn",
                {
                  staticStyle: { float: "right", "margin-top": "12px" },
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _c("v-divider")
            ],
            1
          ),
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c("v-flex", { staticClass: "mt-3" }, [
                            _c("p", { staticClass: "pa-0" }, [
                              _vm._v("Records under DNS Zone:"),
                              _c("i", { staticClass: "small-title" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.formData.item.domain_name) +
                                    " "
                                )
                              ])
                            ])
                          ]),
                          _c(
                            "v-flex",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "v-data-table",
                                {
                                  staticClass: "elevation-1",
                                  attrs: {
                                    headers: _vm.headers,
                                    items: _vm.recordList,
                                    loading: _vm.loading,
                                    "headers-length": "50px",
                                    "hide-actions": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "items",
                                      fn: function(props) {
                                        return [
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.dns_type))
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [_vm._v(_vm._s(props.item.name))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [
                                              props.item.dns_type === "MX"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "[" +
                                                        _vm._s(
                                                          props.item.priority
                                                        ) +
                                                        "],"
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(_vm._s(props.item.ip))
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    color: "rgba(0,0,0,.54)"
                                                  },
                                                  attrs: {
                                                    small: "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openRecord(
                                                        props.item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [_vm._v("edit")])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    color: "rgba(0,0,0,.54)"
                                                  },
                                                  attrs: {
                                                    small: "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$refs.deleterecord.open(
                                                        { record: props.item }
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("delete")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "no-data",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "min-height": "144px",
                                                position: "relative"
                                              }
                                            },
                                            [
                                              _c("Spin", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.loading,
                                                    expression: "loading"
                                                  }
                                                ]
                                              }),
                                              _c("Empty", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.loading,
                                                    expression: "!loading"
                                                  }
                                                ]
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("v-progress-linear", {
                                    attrs: { color: "blue", indeterminate: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "progress",
                                        fn: function() {
                                          return undefined
                                        },
                                        proxy: true
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                position: "sticky",
                bottom: "0",
                background: "#fff",
                width: "100%",
                "border-top": "1px solid #ccc"
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.createrecord.open()
                    }
                  }
                },
                [_vm._v("Add")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CreateRecord", {
        ref: "createrecord",
        on: { confirm: _vm.addRecord }
      }),
      _c(
        "Confirm",
        {
          ref: "deleterecord",
          on: { confirm: _vm.deleteRecord },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function() {
                return [
                  _c("span", [
                    _vm._v(
                      "Are you sure you want to delete this DNS Zone Record?"
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Delete DNS Record")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }