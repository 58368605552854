<template>
    <v-dialog v-model="dialog" max-width="600" persistent no-click-animation>
        <div style="position: relative">
            <div  style="position: sticky;top: 0;background: #fff;width: 100%;z-index: 200">
                <div class="title" style="padding: 20px;display: inline-block; ">
                    DNS Settings
                </div>
                <v-btn icon style="float: right;margin-top: 12px;"  @click="close()">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-divider></v-divider>
            </div>
            <v-form ref="form">
                <v-card>
                    <v-card-text>
                        <v-layout column>
                            <v-flex class="mt-3">
                                <p class="pa-0">Records under DNS Zone:<i class="small-title">
                                    {{formData.item.domain_name}} </i>
                                </p>
                            </v-flex>
                            <v-flex class="mt-3">
                                <v-data-table class="elevation-1" :headers="headers" :items="recordList"
                                              :loading="loading"
                                              headers-length="50px"
                                              hide-actions>
                                    <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                                    <template v-slot:items="props">
                                        <td>{{props.item.dns_type}}</td>
                                        <td class="text-xs-left">{{props.item.name}}</td>
                                        <td class="text-xs-left"><span v-if="props.item.dns_type === 'MX'">[{{props.item.priority}}],</span>{{props.item.ip}}</td>
                                        <td class="text-xs-left">
                                            <v-btn small icon style="display: inline-block;color: rgba(0,0,0,.54);" @click="openRecord(props.item)">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                            <v-btn small icon style="display: inline-block;color: rgba(0,0,0,.54);"
                                                   @click="$refs.deleterecord.open({record: props.item})">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <template v-slot:no-data>
                                        <div style="min-height: 144px; position: relative;">
                                            <Spin v-show="loading"></Spin>
                                            <Empty v-show="!loading"></Empty>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-form>
            <div style="position: sticky;bottom: 0;background: #fff;width: 100%;border-top: 1px solid #ccc;">
                <v-btn class="text-none" color="primary" @click="$refs.createrecord.open()">Add</v-btn>
            </div>
        </div>
        <CreateRecord @confirm="addRecord" ref="createrecord"></CreateRecord>
        <Confirm ref="deleterecord" @confirm="deleteRecord">
            <span slot="title">Delete DNS Record</span>
            <template v-slot:content>
                <span>Are you sure you want to delete this DNS Zone Record?</span>
            </template>
        </Confirm>
    </v-dialog>
</template>

<script>
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import Confirm from '@/components/Confirm'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import {server_name1, server_name2} from "@/module/constant.js"
    import CreateRecord from './CreateRecord'

    export default {
        name: "DNS",
        mixins: [loadingMixin],
        components: {CreateRecord, Empty, Spin, Confirm},
        data() {
            return {
                loading: false,
                server_name1: server_name1,
                server_name2: server_name2,
                headers: [
                    {text: 'Type', sortable: false},
                    {text: 'Name', sortable: false},
                    {text: 'Data', sortable: false},
                ],
                formData: {
                    item: {
                        domain_name: '',
                    },
                },
                recordList: []
            }
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.recordList = []
                    this.$refs.form.resetValidation()
                } else {
                    this.getRecordList()
                }
            },
        },
        methods: {
            openRecord(item) {
                this.$refs.createrecord.open(item)
            },
            getRecordList() {
                this.loading = true
                this.$http.get(`/dns_records?domain_id=${this.formData.item.id}`).then(res => {
                    this.recordList = res.results
                    this.loading = false
                }).catch(() => {
                    // this.$message.error('')
                    this.loading = false
                })
            },
            addRecord(options) {
                const id = this.$refs.createrecord.getEditStatus()
                if (id) {
                    return this.$http({
                        method: 'patch',
                        url: `/dns_records/${id}/`,
                        data: {
                            domain_id: this.formData.item.id,
                            ...options
                        }
                    }).then((res) => {
                        this.getRecordList()
                        this.$message.success('Your changes have been saved successfully!')
                        return Promise.resolve(res)
                    }).catch(err => {
                        this.$message.error(err.detail || 'Oops! Something went wrong.')
                        return Promise.reject(err)
                    })
                } else {
                    return this.$http({
                        method: 'post',
                        url: '/dns_records/',
                        data: {
                            domain_id: this.formData.item.id,
                            ...options
                        }
                    }).then((res) => {
                        this.getRecordList()
                        this.$message.success('Your changes have been saved successfully!')
                        return Promise.resolve(res)
                    }).catch(err => {
                        this.$message.error(err.detail || 'Oops! Something went wrong.')
                        return Promise.reject(err)
                    })
                }
            },
            deleteRecord({record}) {
                return this.$http({
                    method: 'delete',
                    url: `/dns_records/${record.id}/`,
                }).then((res) => {
                    this.getRecordList()
                    this.$message.success('This DNS record has been deleted successfully!')
                    return Promise.resolve(res)
                }).catch(e => {
                    this.$message.error( e.detail || 'Uh-oh! This operation has failed. Please contact us at support@cloudcluster.io.')
                    return Promise.reject(e)
                })
            }
        }
    }
</script>

