<template>
    <v-dialog v-model="dialog" max-width="600" persistent no-click-animation>
        <v-form ref="form">
            <v-card>
                <v-card-title>
                    <div class="title">URL Redirection Management</div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout column>
                        <v-flex>
                            <v-subheader class="pa-0 subheading" style="height: 24px;color: #444;">Http Redirection Option</v-subheader>
                            <v-switch
                                    :disabled="!hasSSL"
                                    class="mt-2 pr-2"
                                    v-model="formData.to_https"
                                    color="primary"
                                    dense
                                    hide-details>
                                <template v-slot:label>
                                    <span style="font-size: 14px;">Redirect HTTP to HTTPs</span>
                                </template>
                            </v-switch>
                            <p v-show="!hasSSL" style="color: red;padding-left: 44px;font-size: 13px">Unavailable as you have not enabled SSL for the domain.</p>
                        </v-flex>
                        <v-flex v-if="can_redirect">
                            <v-subheader class="pa-0 subheading" style="height: 24px;color: #444;">URL Redirection Options</v-subheader>
                            <v-radio-group v-model="formData.redirect" class="mt-1">
                                <v-radio v-for="(o,k) in selectItems" color="primary"
                                         :key="k"
                                        :label="o.text"
                                        :value="o.value"
                                ></v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex class="mt-3" style="display: inline-block;text-align: right;">
                            <v-btn class="text-none" color="blue darken-1" flat @click="close()">Cancel</v-btn>
                            <v-btn class="text-none" color="primary" @click="ok()" :loading="loading">Submit</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'


    let selectItems = [
        {text:'Non redirection',value:'do_nothing'},
        {text:'Redirect www to non-www',value:'to_no_www'},
        {text:'Redirect non-www to www',value:'to_www'},
        ]

    export default {
        name: "Redirect",
        mixins: [loadingMixin],
        data() {
            return {
                selectItems,
                can_redirect: false,
                formData: {
                    domain: null,
                    to_https : false,
                    redirect:'',
                }
            }
        },
        computed: {
            hasSSL (){
                if(!this.formData.domain){
                    return false
                }else if(this.formData.domain.certificate.private_key || this.formData.domain.freessldomain.process === 'Success'){
                    return true
                }else{
                    return false
                }
            }
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.resetValidation()
                }
            },
        },
        methods: {
            $validator() {
                return this.$refs.form.validate()
            },
            open({item}) {
                this.domain_name = item.domain_name
                this.can_redirect = item.can_redirect
                this.formData = {
                    domain : item,
                    to_https : item.redirect_manage.to_https,
                    redirect: item.redirect_manage.redirect,
                }
                this.dialog = true
            },
        }
    }
</script>

<style lang="scss">
    .site-ul{
        list-style: none;
        li:before {
            content:'';
            display: inline-block;
            position: relative;
            width: 6px;
            height: 6px;
            background-color: #3F51B5;
            border-radius: 3px;
            left: -10px;
            top: -2px;
        }
    }

</style>
