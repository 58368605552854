var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Spin",
    {
      staticClass: "site-process",
      staticStyle: { background: "rgba(255,255,255,.7)" }
    },
    [
      [
        _vm.isProcess
          ? _c("v-progress-circular", {
              attrs: { size: 35, color: "blue lighten-3", indeterminate: "" }
            })
          : _vm._e(),
        _c("div", { staticClass: "mt-4", class: _vm.errorClass }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.isProcess
                  ? _vm.siteDetail.status
                  : _vm.messageMap[_vm.siteDetail.status]
              )
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }