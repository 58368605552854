<template>
    <div class="site-panel">
        <v-card :hover="siteDetail.status === 'Success'" style="cursor: default;">
            <v-card-title class="py-0 pr-0 title" style="height: 40px">
                <span :style="{color:$vuetify.theme.primary}">{{siteDetail.nickname}}</span>
                <v-spacer></v-spacer>
                <template v-if="!siteDetail.is_default">
                    <v-btn :disabled="['Installing','Deleting'].indexOf(siteDetail.status) !== -1" icon v-on="on" @click="$emit('delete-site',siteDetail)">
                        <i class="fa fa-trash fa-lg" style="color:#999;"
                           aria-hidden="true"></i>
                    </v-btn>
                </template>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-0">
                <template >
                    <v-layout wrap style="background: #eaebec" class="px-3 py-2 mt-2 body-1" v-if="information.length">
<!--                        <PropertyTable cols="2" text-key="name" text-value="value" :items="information" ></PropertyTable>-->
                        <v-flex sm12 xs12 md12 d-flex v-for="(item, idx) in information" :key="idx">
                            <v-layout>
                                <v-flex xs3 class="pa-1" >{{item.name}}</v-flex>
                                <v-flex xs9 class="pa-1">
                                    <div class="text-truncate" style="display: block;line-height: 1.5em !important;" v-http="item.value" ></div>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-layout>
                        <v-data-table class="domain-table elevation-0" :headers="headers" :items="domainList" hide-actions>
                            <template v-slot:items="{item}">
                                <td class="pl-2">
                                    <v-layout no-wrap>
                                        <v-flex grow>
                                            <v-icon small color="primary">language</v-icon>&nbsp;
                                            <a :href="item.domain_url" target="_blank" class="span-font text-truncate" >{{item.domain_name }}</a>
                                        </v-flex>
                                        <v-flex>
                                            <span v-if="item.is_primary && showPrimary" class="elliptic-box span-font px-2">Primary</span>
                                        </v-flex>
                                    </v-layout>
                                </td>
                                <td>{{(deploymentInfo&&deploymentInfo.ips)?deploymentInfo.ips[0]:''}}</td>
                                <td>
                                    <v-layout row>
                                        <v-flex d-flex align-center style="flex:0 1 140px;">
                                            <div class="text-box">
                                                <template v-if="item.is_platform_default">
                                                    <span class="span-font mx-2" :title="`Install SSL certificate for your site.`">None</span>
                                                </template>
                                                <template v-else>
                                                    <template v-if="item.freessldomain.id&&item.freessldomain.process!=='Failed'">
                                                        <FreeSSLProcess v-if="item.freessldomain.process==='Processing'"
                                                                        @success="refreshSite"
                                                                        :id="item.freessldomain.id"></FreeSSLProcess>
                                                        <span v-else class="span-font elliptic-box px-2 mx-1">Let's Encrypt</span>
                                                    </template>
                                                    <template v-else-if="item.certificate.certificate">
                                                        <span class="span-font elliptic-box px-2 mx-1">Custom Cert</span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="span-font mx-2">None</span>
                                                    </template>
                                                    <CertMenus v-if="!item.is_platform_default" :domain="item"
                                                               @operator="operatorHandler"
                                                               @installCert="$refs.installcert.open(item)"></CertMenus>
                                                </template>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </td>
                                <td class="pr-0">
                                    <span style="display:inline-block;width: 44px;">
                                        <v-menu :disabled="showMenus(item)" transition="slide-x-transition" v-if="item.freessldomain.process!=='Processing'">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on" @click.prevent :disabled="showMenus(item)">
                                                    <i class="fa fa-cog fa-lg" :title="`Manage DNS, URL redirection, etc.`" :style="{color:showMenus(item)?'#ccc':$vuetify.theme.primary}"
                                                       aria-hidden="true"></i>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-tile v-if="showPrimary"
                                                             :disabled="item.is_primary"
                                                             @click="!item.is_primary && $refs.makePrimary.open({domain:item})">
                                                    <v-list-tile-title>Make primary</v-list-tile-title>
                                                </v-list-tile>

                                                <v-list-tile v-if="!item.is_platform_default && item.is_our_nameserver || item.is_enabled"
                                                             @click="$refs.dns.open({item})">
                                                    <v-list-tile-title>Manage DNS</v-list-tile-title>
                                                </v-list-tile>
                                                 <v-list-tile v-if="!item.is_our_nameserver && !item.is_platform_default && !item.is_enabled"
                                                              @click="$refs.enabledns.open({domain_uuid: item.uuid})">
                                                    <v-list-tile-title>Enable DNS</v-list-tile-title>
                                                </v-list-tile>
                                                <v-list-tile v-if="!item.is_platform_default" @click="$refs.redirect.open({item})">
                                                    <v-list-tile-title>URL Redirect</v-list-tile-title>
                                                </v-list-tile>
                                                <v-list-tile v-if="!item.is_platform_default"
                                                             @click="$refs.deleteDomain.open({domain:item})">
                                                    <v-list-tile-title>Delete</v-list-tile-title>
                                                </v-list-tile>
                                            </v-list>
                                        </v-menu>
                                    </span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-layout>
                </template>
                    <SiteProcess v-if="siteDetail.status !== 'Success'" :site-detail="siteDetail" @done-refresh="refreshSite"></SiteProcess>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn :title="`Add your own domain.`" :id="`site_idx_${idx}`" :disabled="siteDetail.status !== 'Success'"  class=" mx-0 text-none"  small color="primary" @click="$refs.adddomain.open()"> Create Domain</v-btn>
                <v-btn v-if="['phpclusters','javaclusters','pythonclusters','aspnetclusters','nodejsclusters'].indexOf(productName)=='-1'" :title="`Reset the password of your admin user.`" :disabled="siteDetail.status !== 'Success'"  class=" mx-0 text-none ml-3"  outline small color="primary" @click="$refs.password.open()">{{passwordText}}</v-btn>
            </v-card-actions>
        </v-card>
        <Redirect ref="redirect" @confirm="updateRedirect" ></Redirect>
        <InstallCert @confirm="installCertificate" ref="installcert"></InstallCert>
        <AddDomain ref="adddomain" @confirm="addDomain"></AddDomain>
        <DNS ref="dns"></DNS>
        <UserPassword v-if="siteDetail.status === 'Success' && ['phpclusters','javaclusters','pythonclusters','aspnetclusters','nodejsclusters'].indexOf(productName)=='-1'" ref="password" :site-detail="siteDetail" @confirm="updatePassword"></UserPassword>
        <Confirm ref="deleteDomain" @confirm="deleteDomain">
            <span slot="title">Delete domain</span>
            <template v-slot:content="{options}">
                <span v-if="options.domain&& options.domain.domain_name"> Are you sure you want to delete  <b> {{options.domain.domain_name}}</b>?
                        Without it, http(s)://{{options.domain.domain_name}}.com will not be accessible.
                </span>
            </template>
        </Confirm>
        <Confirm ref="makePrimary" @confirm="makePrimary">
            <span slot="title">Make primary</span>
            <template v-slot:content="{options}">
                <span v-if="options.domain&& options.domain.domain_name">Are you sure you want to make <b>{{options.domain.domain_name}} </b> primary?</span>
            </template>
        </Confirm>
        <Confirm ref="enabledns" @confirm="enableDNS">
            <span slot="title">Enable DNS</span>
            <template v-slot:content>
                <span>You’ll need to update the name servers at your current domain registrar as below:<br>
                    <b>{{server_name1}} & {{server_name2}}</b><br>
                    Please be aware that changes may take up to 24 hours to propagate.
                </span>
            </template>
        </Confirm>

        <Confirm ref="cert" @confirm="certCallback">
            <template v-slot:content="{options}">
                <span> {{options.content}}</span>
            </template>
        </Confirm>

    </div>
</template>

<script>
    import AddDomain from './AddDomain.vue'
    import Redirect from './Redirect'
    import DNS from './DNS.vue'
    import CertMenus from './CertMenus.vue'
    import UserPassword from './Password.vue'
    import InstallCert from './installCert.vue'
    // import PropertyTable from '@/components/PropertyTable'
    import FreeSSLProcess from './FreeSSLProcess.vue'
    import SiteProcess from './SiteProcess'
    import Confirm from '@/components/Confirm'
    import { mapGetters, mapState} from "vuex";
    import {server_name1, server_name2} from "@/module/constant.js"

    export default {
        name: "Site",
        data() {
            return {
                loading: false,
                server_name1: server_name1,
                server_name2: server_name2,
                headers: [
                    {text: 'Domain', value: 'domain',align:'left', class:'pa-0 pl-2', sortable: false},
                    {text: 'IP', value: 'ip', align:'left', class:'py-0',sortable: false},
                    {text: 'Cetificate', value: 'cetificate', align:'left', class:'py-0',sortable: false},
                    {text: ' ', value: 'op', align:'right', class:'pa-0',sortable: false},
                ],
            }
        },
        props : ['siteDetail', 'idx'],
        components: {
            Redirect,
            Confirm,
            DNS,
            InstallCert,
            CertMenus,
            FreeSSLProcess,
            AddDomain,
            UserPassword,
            SiteProcess,
            // PropertyTable
        },
        computed: {
            domainList (){
                return this.siteDetail.domains_set || []
            },
            deploymentInfo(){
                return this.siteDetail.deployment_info
            },
            information() {
                return this.deploymentInfo.connection_info ? this.deploymentInfo.connection_info[0].cmds : []
            },
            showPrimary() {
                return ['drupal','phpclusters','javaclusters','pythonclusters','aspnetclusters','odoo','nodejsclusters','opencart'].indexOf(this.productName) === -1
                // return ['magento', 'wordpress','opencart'].indexOf(this.productName) !== -1
            },
            passwordText() {
              return this.productName==='odoo'?'Reset Master Password':'Reset Password'
            },
            productName() {
                return this.appInfo.product_name.toLowerCase()
            },
            ...mapGetters('application', ['getServiceId']),
            ...mapState('application', ['appInfo']),
        },
        methods: {
            showMenus(item){
                let flag = true
                if(this.showPrimary){
                    flag  = false
                }
                if(!item.is_platform_default && item.is_our_nameserver || item.is_enabled){
                    flag  = false
                }
                if(!item.is_our_nameserver && !item.is_platform_default && !item.is_enabled){
                    flag  = false
                }
                if(!item.is_platform_default){
                    flag  = false
                }
                return flag
            },
            certCallback(options){
                return options.cb().then(() => {
                    this.refreshSite()
                })
            },
            updateRedirect({domain,redirect,to_https}){
                return this.$http.put(`/domains/${domain.uuid}/update_redirect/`, {
                    redirect,to_https
                }).then(() => {
                    this.refreshSite()
                    this.$message.success('The Redirect updated was successfully.')
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            updatePassword({name, password}){
                return this.$http.put('/admins/reset-password/', {
                    name, password,
                    service_uuid: this.getServiceId(this.appInfo),
                    app_site_id : this.siteDetail.id
                }).then(() => {
                    this.$message.success('The password was reset successfully.')
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            enableDNS({domain_uuid}) {
                return this.$http.post(`/domains/${domain_uuid}/enable-dns/`).then((res) => {
                    this.refreshSite()
                    this.$message.success('The DNS has been enabled successfully!')
                    return Promise.resolve(res)
                }).catch(err => {
                    this.$message.error(err.detail)
                    return Promise.reject(err)
                })
            },
            addDomain({domain_name, is_our_nameserver}) {
                return this.$http.post('/domains/', {
                    domain_name: domain_name,
                    is_our_nameserver: is_our_nameserver,
                    service_uuid: this.getServiceId(this.appInfo),
                    app_site : this.siteDetail.id
                }).then(() => {
                    this.refreshSite()
                    this.$message.success(`The domain name ${domain_name} was created successfully.`)
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            deleteDomain({domain}) {
                return this.$http.delete(`/domains/${domain.uuid}/`).then(() => {
                    this.refreshSite()
                    this.$message.success('Your domain was deleted successfully.')
                }).catch((e) => {
                    this.$message.error(e.detail)
                    // this.$message.error('Oops, it failed to delete the domain. Please contact us at support@cloudclusters.io for this issue.')
                })
            },
            makePrimary({domain}) {
                return this.$http.post(`/domains/${domain.uuid}/make-primary/`).then(() => {
                    this.refreshSite()
                    this.$message.success('Another domain was made primary domain.')
                }).catch((e) => {
                    this.$message.error(e.detail)
                    // this.$message.error('Oops, it failed to make the domain primary domain. Please contact us at support@cloudclusters.io for this issue.')
                })
            },
            installCertificate(options) {
                return this.$http({
                    method: options.type==='Install'? 'post':'put',
                    url: options.type==='Install'?'/certificates/':`/certificates/${options.certificate_uuid}/`,
                    data: options
                }).then((res) => {
                    this.refreshSite()
                    this.$message.success('The custom SSL certificate was installed successfully.')
                    return Promise.resolve(res)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    // this.$message.error('Oops, your SSL certificate installation failed, please make sure it is a valid certificate or contact us at support@cloudclusters.io.')
                })
            },
            refreshSite(status){
                if(status === 'DeleteSuccess'){
                    this.$emit('refresh-site',this.siteDetail,status)
                }else{
                    return this.$http.get(`/app_sites/${this.siteDetail.id}/`).then((res) => {
                        this.$emit('refresh-site',res,status)
                    })
                }
            },
            operatorHandler(opt){
                this.$refs.cert.open(opt)
            },
        },

    }
</script>

<style lang="scss">

    .site-panel{
        .title{
            position: relative;
            &:before{
                display: block;
                content: ' ';
                width: 2px;
                height: 100%;
                background-color: #3f51b5;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .domain-table{
            width: 100%;
            .theme--light.v-table thead tr:first-child{
                border-bottom: 0 solid transparent;
                height: 40px;
            }
        }
    }
</style>