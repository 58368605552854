<template>
    <Spin style="background: rgba(255,255,255,.7)" class="site-process">
        <template >
            <v-progress-circular
                    v-if="isProcess"
                    :size="35"
                    color="blue lighten-3"
                    indeterminate
            ></v-progress-circular>
            <div class="mt-4" :class="errorClass"> {{isProcess?siteDetail.status:messageMap[siteDetail.status]}}</div>
        </template>
    </Spin>
</template>

<script>
    import Spin from '@/components/Spin.vue'

    let interver = 5000

    export default {
        name: "SiteProcess",
        data(){
            return {
                timer: null,
                isSuccess: false
            }
        },
        props : ['siteDetail'],
        components : {Spin},
        computed : {
            errorClass(){
                return  this.isProcess?[]:['site-error']
            },
            isProcess(){
                return ['Installing','Deleting'].indexOf(this.siteDetail.status) !== -1
            },
            messageMap(){
                return {
                    Success: `The site ${this.siteDetail.nickname} has been added successfully.`,
                    Failed : `It failed to add the site ${this.siteDetail.nickname}.`,
                    DeleteSuccess : `The site ${this.siteDetail.nickname} has been deleted successfully.`,
                    DeleteFailed : `It failed to delete the site ${this.siteDetail.nickname}.`,
                }
            }
        },
        methods : {
            getStatus() {
                return new Promise((resolve,reject) => {
                    this.$http.get(`/app_sites/${this.siteDetail.id}/get_site_status/`).then((res) => {
                        resolve(res)
                    }).catch((e) => {
                        reject(e)
                    })
                })
            },
            tick(){
                if (this.isSuccess) {
                    return
                }
                this.getStatus().then(status => {
                    if(['Installing','Deleting'].indexOf(status) !== -1){
                        this.timer =  setTimeout(() => {
                            this.tick()
                        },interver)
                    }else {
                        if (status === 'DeleteSuccess' || status === 'Success' ) {
                            this.$message.success(this.messageMap[status])
                        }
                        if (status === 'Failed' || status === 'DeleteFailed' ) {
                            this.$message.error(this.messageMap[status])
                        }
                        this.isSuccess = true
                        this.$emit('done-refresh',status)
                        clearTimeout(this.timer)
                    }
                }).catch(() => {
                    this.timer =  setTimeout(() => {
                        this.tick()
                    },interver)
                })
            }
        },
        created() {
            if(this.isProcess){
                this.tick()
            }
        },
        beforeDestroy(){
            this.isSuccess = true
            clearTimeout(this.timer)
        }
    }
</script>

<style lang="scss">
    .site-process{
        .site-error{
            color: #FF5252;
        }
    }

</style>