var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper domain-wrapper panel-database-detail pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Site & SSL" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticStyle: { "margin-top": "-24px" },
                      attrs: { wrap: "" }
                    },
                    [
                      _c("v-flex", { attrs: { xs12: "", md10: "" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "fs-3 font-weight-regular pt-4",
                            staticStyle: { color: "#666", margin: "0" }
                          },
                          [
                            _vm._v(
                              "\n                            This is where you manage your websites, DNS, SSL, Domain, and URL redirection.\n                            "
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            { attrs: { color: "primary" } },
                                            on
                                          ),
                                          [_vm._v("report")]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _c("span", [_vm._v("Notes:")]),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      "Please update DNS A record to point your domain to " +
                                        _vm._s(_vm.appInfo.ip[0])
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Please click “Enable DNS” and update name server to ns1,ns2.cloudclusters.net if you\n                                        want to use our name servers\n                                    "
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "You can enable free SSL after your domain has been resolved to " +
                                        _vm._s(_vm.appInfo.ip[0])
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "You can manage URL redirection for SEO"
                                    )
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.appInfo.product_name === "WordPress"
                          ? _c(
                              "p",
                              {
                                staticClass: "mb-0 mt-1",
                                staticStyle: { color: "#039BE5" }
                              },
                              [
                                _vm._v(
                                  "You are\n                            currently using "
                                ),
                                _c(
                                  "span",
                                  {
                                    style: { color: _vm.$vuetify.theme.error }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.list ? _vm.list.length : 0) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm._v(
                                  " out of\n                            " +
                                    _vm._s(_vm.appInfo.max_site_number) +
                                    " " +
                                    _vm._s(_vm.appInfo.product_name) +
                                    " sites"
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm.appInfo.product_name === "WordPress"
                        ? [
                            _c(
                              "v-flex",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "flex-end"
                                },
                                attrs: { xs12: "", md2: "" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-3 mx-0 text-none",
                                    attrs: {
                                      disabled:
                                        !_vm.list ||
                                        _vm.list.length >=
                                          _vm.appInfo.max_site_number,
                                      color: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.addSite.open()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { dark: "", left: "" } },
                                      [_vm._v("add")]
                                    ),
                                    _vm._v(
                                      "\n                                Add New Site\n                            "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm.list
                    ? _vm._l(_vm.list, function(site, idx) {
                        return _c("Site", {
                          key: site.id,
                          staticClass: "mt-4",
                          attrs: { idx: idx, "site-detail": site },
                          on: {
                            "delete-site": _vm.deleteConfirm,
                            "refresh-site": _vm.updateSite
                          }
                        })
                      })
                    : _vm._e(),
                  !_vm.list
                    ? [
                        _c(
                          "div",
                          { staticStyle: { height: "400px" } },
                          [_c("Spin")],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.list && _vm.list.length === 0
                    ? [
                        _c(
                          "div",
                          { staticStyle: { height: "400px" } },
                          [_c("Empty")],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c("AddSite", {
                    ref: "addSite",
                    on: { confirm: _vm.addSiteHandler }
                  }),
                  _c(
                    "Confirm",
                    {
                      ref: "deleteSite",
                      on: { confirm: _vm.deleteSiteHandler },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function(ref) {
                            var options = ref.options
                            return [
                              options.item && options.item.nickname
                                ? _c("span", [
                                    _vm._v(
                                      " Are you sure you want to delete  "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        " " + _vm._s(options.item.nickname)
                                      )
                                    ]),
                                    _vm._v("?")
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("Delete Site")
                      ])
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }