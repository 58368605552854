<template>
        <v-menu v-if="domain.freessldomain.process!=='Processing'" transition="slide-x-transition">
            <template v-slot:activator="{ on }">
                <v-icon style="position: absolute;right: 5px;" v-on="on" small>keyboard_arrow_down</v-icon>
            </template>
            <v-list >
                <v-list-tile
                        v-for="(item, i) in currentMenus" @click="item.handler" :key="i">
                    <v-list-tile-title>{{item.text }}</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
</template>

<script>
    export default {
        name: "CertMenus",
        props: {
            domain: Object
        },
        data() {
            return {
                certMenus: {
                    none: [
                        {text: 'Enable Free SSL' , handler : this.enableFreeSSL},
                        {text: 'Install Custom Cert' ,handler : this.openCustomCert}
                    ],
                    customCert: [
                        {text: 'View Certificate',handler : this.openCustomCert},
                        {text: 'Remove',handler : this.removeCustomCert}
                    ],
                    freeCert: [
                        {text: 'Remove',handler : this.removeFreeSSL}
                    ]
                },
            }
        },
        computed: {
            currentMenus() {
                if (this.domain.freessldomain.process && this.domain.freessldomain.process === 'Success') {
                    return this.certMenus.freeCert
                } else if (this.domain.certificate.certificate) {
                    return this.certMenus.customCert
                }else {
                    return this.certMenus.none
                }
            }
        },
        methods: {
            enableFreeSSL() {
                let self = this
                this.$emit('operator', {
                    content : 'Are you sure you want to enable free SSL ?',
                    cb (){
                       return self.$http.post(`/domains/${self.domain.uuid}/enable-freessl/`).then(() => {
                            // self.$message.success('The free SSL was installed successfully.')
                        }).catch((e) => {
                            self.$message.error(e.detail)
                        })
                    }
                })
            },
            removeFreeSSL() {
                let self = this
                this.$emit('operator', {
                    content : 'Are you sure you want to remove free SSL ?',
                    cb (){
                       return self.$http.delete(`/domains/${self.domain.uuid}/disable-freessl/`).then(() => {
                           self.$message.success('Free SSL was removed.')
                        }).catch((e) => {
                           self.$message.error(e.detail)
                        })
                    }
                })
            },
            openCustomCert(){
                this.$emit('installCert', this.domain)
            },
            removeCustomCert() {
                let self = this
                this.$emit('operator', {
                    content : 'Are you sure you want to remove the custom SSL certificate ?',
                    cb (){
                        return  self.$http.delete(`/certificates/${self.domain.certificate.uuid}/`).then(() => {
                            self.$message.success('The custom SSL certificate was deleted.')
                        }).catch((e) => {
                            self.$message.error(e.detail)
                        })
                    }
                })
            },
        }
    }
</script>

<style lang="scss">

</style>
