<template>
    <v-dialog v-model="dialog" max-width="600" persistent no-click-animation>
        <v-form ref="form" v-insert="{tagName:'input',attrs:{style:'display:none',type:'text'}}">
            <v-card>
                <v-card-title>
                    <div class="title">Add New Site</div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout column v-single-input>
                        <v-flex>
                            <v-text-field v-model="formData.nick_name"
                                          :rules="[rules.required,rules.counter32,rules.n_or_c,rules.noNumOnly]"
                                          hint="Maximum 32 characters, and contain only numbers and lowercase letters."
                                          persistent-hint
                                          clearable>
                                <template v-slot:label>
                                    <RequiredDot>Site Nickname</RequiredDot>
                                </template>
                            </v-text-field>
                        </v-flex>
                        <v-flex class="mt-2">
                            <ul class="site-ul">
                                <li>This nickname will be used to uniquely identify your site in Control Panel.</li>
                                <li>Visitors  to your site will never see the nickname</li>
                                <li>It can not be changed</li>
                            </ul>
                        </v-flex>
                        <v-flex class="mt-3" style="display: inline-block;text-align: right;">
                            <v-btn class="text-none" color="blue darken-1" flat @click="close()">Cancel</v-btn>
                            <v-btn class="text-none" color="primary" @click="ok()" :loading="loading">Create</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import RequiredDot from '@/components/RequiredDot.vue'
    import rules from '@/module/rules/index.js'

    export default {
        name: "AddSite",
        components: {
            RequiredDot
        },
        mixins: [loadingMixin],
        data() {
            return {
                rules,
                // nameRule(value){
                //     return  !value || value.length >= 8 || 'Minimum 8 characters'
                //     // return /^bai[a-z0-9]${32}$/u
                //
                // },
                formData: {
                    nick_name: '',
                }
            }
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.formData.nick_name = ''
                    this.$refs.form.resetValidation()
                }
            },
        },
        methods: {
            $validator() {
                return this.$refs.form.validate()
            }
        }
    }
</script>

<style lang="scss">
    .site-ul{
        list-style: none;
        li:before {
            content:'';
            display: inline-block;
            position: relative;
            width: 6px;
            height: 6px;
            background-color: #3F51B5;
            border-radius: 3px;
            left: -10px;
            top: -2px;
        }
    }

</style>
