var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600",
        scrollable: "",
        persistent: "",
        "no-click-animation": ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("div", { staticClass: "title" }, [
                  _vm._v("View/Update Certificate")
                ]),
                _c("br")
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticStyle: { "max-height": "550px" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", [
                        _c("p", { staticClass: "sub-title" }, [
                          _vm._v("Target Domain: "),
                          _c(
                            "i",
                            {
                              staticStyle: {
                                color: "blue",
                                "font-style": "normal"
                              }
                            },
                            [_vm._v(_vm._s(_vm.domain_name))]
                          )
                        ])
                      ]),
                      _c("v-flex", [
                        _c("p", { staticClass: "input-tips" }, [
                          _vm._v(
                            "\n                            SSL certificates are used to encrypt information transferred between websites and browsers.\n                            This is where you can install a certificate for your website.\n                        "
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-subheader",
                            { staticClass: "small-title px-0" },
                            [
                              _vm._v(
                                "\n                            Certificate(CRT)\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { "my-2": "" } },
                        [
                          _c("span", { staticClass: "input-tips" }, [
                            _vm._v(
                              "The following text box is for encrypted certificate file\n                            content"
                            )
                          ]),
                          _c("v-textarea", {
                            staticClass: "mt-2",
                            attrs: { solo: "", rules: [_vm.rules.required] },
                            model: {
                              value: _vm.formData.certificate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "certificate", $$v)
                              },
                              expression: "formData.certificate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-subheader",
                            { staticClass: "small-title px-0" },
                            [
                              _vm._v(
                                "\n                            Private Key(KEY)\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { "my-2": "" } },
                        [
                          _c("span", { staticClass: "input-tips" }, [
                            _vm._v(
                              "The following text box is content of private key"
                            )
                          ]),
                          _c("v-textarea", {
                            staticClass: "mt-2",
                            attrs: { solo: "", rules: [_vm.rules.required] },
                            model: {
                              value: _vm.formData.private_key,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "private_key", $$v)
                              },
                              expression: "formData.private_key"
                            }
                          }),
                          _c("span", { staticClass: "input-tips" }, [
                            _vm._v(
                              "Warning: if there is already a certificate installed for this\n                            domain,it will be overwritten by this one"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-action",
                [
                  _c("v-divider"),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "blue darken-1", flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c("v-btn", {
                        staticClass: "text-none",
                        attrs: { color: "primary", loading: _vm.loading },
                        domProps: {
                          textContent: _vm._s(
                            _vm.loading ? "" : _vm.formData.type
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.ok()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }