<template>
    <v-dialog v-model="dialog" max-width="600" scrollable persistent no-click-animation>
        <v-form ref="form">
            <v-card>
                <v-card-title>
                    <div class="title">View/Update Certificate</div>
                    <br>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="max-height: 550px">
                    <v-layout column>
                        <v-flex>
                            <p class="sub-title">Target Domain: <i style="color: blue;font-style: normal;">{{domain_name}}</i>
                            </p>
                        </v-flex>
                        <v-flex>
                            <p class="input-tips">
                                SSL certificates are used to encrypt information transferred between websites and browsers.
                                This is where you can install a certificate for your website.
                            </p>
                        </v-flex>
                        <v-flex>
                            <v-subheader class="small-title px-0">
                                Certificate(CRT)
                            </v-subheader>
                        </v-flex>
                        <v-flex my-2>
                            <span class="input-tips">The following text box is for encrypted certificate file
                                content</span>
                            <v-textarea class="mt-2" solo :rules="[rules.required]"
                                        v-model="formData.certificate"></v-textarea>
                        </v-flex>
                        <v-flex>
                            <v-subheader class="small-title px-0">
                                Private Key(KEY)
                            </v-subheader>
                        </v-flex>
                        <v-flex my-2>
                            <span class="input-tips">The following text box is content of private key</span>
                            <v-textarea class="mt-2" solo :rules="[rules.required]"
                                        v-model="formData.private_key"></v-textarea>
                            <span class="input-tips">Warning: if there is already a certificate installed for this
                                domain,it will be overwritten by this one</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-action>
                    <v-divider></v-divider>
                    <div  style="text-align: right">
                        <v-btn class="text-none"  color="blue darken-1" flat @click="close()">Cancel</v-btn>
                        <v-btn class="text-none" color="primary" @click="ok()" :loading="loading"
                                v-text="loading?'':formData.type"></v-btn>
                    </div>
                </v-card-action>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'

    export default {
        name: "InstallCert",
        mixins: [loadingMixin],
        data() {
            return {
                rules: {...rules},
                flag: true,
                domain_name: "",
                formData: {
                    domain_uuid: "",
                    certificate: "",
                    certificate_uuid:'',
                    private_key: "",
                    type:''
                },
            }
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.formData = {
                        domain_uuid: "",
                        certificate: "",
                        certificate_uuid:'',
                        private_key: "",
                        type:''
                    }
                    this.domain_name = ""
                    this.$refs.form.resetValidation()
                }
            }
        },
        methods: {
            open(options) {
                this.formData.type = options.certificate.certificate ? 'Update' : 'Install'
                this.domain_name = options.domain_name
                this.formData.domain_uuid = options.uuid
                this.formData.certificate_uuid = options.certificate.uuid
                this.formData.certificate = options.certificate.certificate
                this.formData.private_key = options.certificate.private_key
                this.dialog = true
            },
            $validator() {
                return this.$refs.form.validate()
            }
        },

    }
</script>

<style lang="scss">

</style>
